import React, {useContext} from "react"
import styled from 'styled-components'
import { FirebaseContext} from '@components/Firebase';
import { ListViewItem, AppPageHeader } from '@common';
import Text from  '@typo/Text'
import MainHeading from  '@typo/MainHeading'
import { graphql } from "gatsby"
import { useTranslation } from 'react-i18next'
import { usePageContext }  from '@hooks/usePageContext'
import { useObjectifyStoryArray } from '@hooks/useObjectifyStoryArray'
import Header from "@menus/header"

const FilterWrapper = styled.div`
  padding: 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
`

const ShowMore = (props) => {
  
  const stories = useObjectifyStoryArray(props.data.allStory)
  const contentIds = props.location.state?.contentIds ?  props.location.state?.contentIds : [];
  const { lang}  = usePageContext()
  const { t } = useTranslation()
  const { user } = useContext(FirebaseContext)
  

  return (
    <>
    <Header location={props.location} /> 
    <AppPageHeader pageTitle={t('filter-results.sectionTitle-1')}/>
    { user && 
      <FilterWrapper>

      {
        contentIds.length < 1 ?
          <>
            <MainHeading>{t('filter-results.sectionTitle-2')}</MainHeading> 
            <Text>{t('filter-results.sectionSubtitle-2')}</Text>
            {props.data.allStory.edges.filter(item => item.node[lang].published === true).map(edge =>(
              <ListViewItem
                id={edge.node.id}
                slug={edge.node[lang].slug}
                title={edge.node[lang].title}
                sampleAudioRef={edge.node[lang].sampleAudioRef}
                storyCover={edge.node.localImage.childImageSharp.gatsbyImageData}
                thumbnail={edge.node.localImage.smallImage.gatsbyImageData}
                duration={edge.node[lang].duration}
                voices={edge.node[lang].voices}
                mainFantasy={edge.node.mainFantasy[lang].fantasy}
                spiciness={edge.node.spiciness}
                premiumContent={edge.node[lang].premiumContent}
                audiodesiresOriginal={edge.node.audiodesiresOriginal}
                key={edge.node.id}
              />
            ))} 
          </>
        :
        <>
          {contentIds.map(id =>(
            <ListViewItem
              id={stories[id].id}
              slug={stories[id][lang].slug}
              title={stories[id][lang].title}
              sampleAudioRef={stories[id][lang].sampleAudioRef}
              storyCover={stories[id].localImage.childImageSharp.gatsbyImageData}
              thumbnail={stories[id].localImage.smallImage.gatsbyImageData}
              duration={stories[id][lang].duration}
              voices={stories[id][lang].voices}
              mainFantasy={stories[id].mainFantasy[lang].fantasy}
              spiciness={stories[id].spiciness}
              premiumContent={stories[id][lang].premiumContent}
              audiodesiresOriginal={stories[id].audiodesiresOriginal}
              key={stories[id].id}
            />
          ))}
        </>
      }
      </FilterWrapper>
    }
    </>
  )
}

export default  ShowMore ; 

export const query = graphql`{
  allStory {
    edges {
      node {
        audiodesiresOriginal
        id
        spiciness
        fantasies {
          en {
            fantasy
          }
          es {
            fantasy
          }
          de {
            fantasy
          }
        }
        en {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
          }
        }
        es {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
          }
        }
        de {
          duration
          featured
          freeAudioRef
          premiumAudioRef
          premiumContent
          published
          sampleAudioRef
          seoDescription
          seoTitle
          seriesStories
          slug
          summary
          title
          voices{
            name
          }
        }
        localImage {
          childImageSharp {
            gatsbyImageData(width: 300, height: 300, layout: CONSTRAINED)
          }
        }
        localImage {
          smallImage: childImageSharp {
            gatsbyImageData(width: 100, height: 100, layout: CONSTRAINED)
          }
        }
        mainFantasy {
          en {
            fantasy
          }
          es {
            fantasy
          }
          de {
            fantasy
          }
        }
        fantasies {
          id
        }
        genre {
          id
          en{
            genre
          }
          es{
            genre
          }
          de{
            genre
          }
          id
        }
        scenario {
          en {
            scenario
          }
          es {
            scenario
          }
          de {
            scenario
          }
        }
      }
    }
  }
}
` 